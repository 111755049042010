import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Header from './header'
import { Global } from '@emotion/core'

const globalStyles = {
  body: {
    color: '#333',
    padding: 0,
    margin: 0,
    fontFamily: 'Alegreya Sans',
    fontWeight: 300,
  },
  p: {
    margin: 0,
  },
  'h1, h2, h3': {
    fontFamily: 'Alegreya SC',
  },
}

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={(data) => (
      <div
        css={{
          background: '#ddd',
          minHeight: '100vh',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Global styles={globalStyles} />
        <Header
          siteTitle={data.site.siteMetadata.title}
          siteDescription={data.site.siteMetadata.description}
        />
        <div
          css={{
            textAlign: 'center',
            margin: `0 auto`,
            maxWidth: 960,
            padding: `0px 1.0875rem 1.45rem`,
            paddingTop: 0,
          }}
        >
          <main>{children}</main>
          <footer css={{ color: '#666', paddingTop: '3em' }}>
            <div
              css={{
                color: '#555',
                marginBottom: '1em',
                textAlign: 'center',
              }}
            >
              <p mb={3}>
                <span title="Umsatzsteuernummer">ATU74832336</span> &middot;{' '}
                <span title="Firmenbuchnummer">FN520213w</span> &middot;{' '}
                <span title="Firmenbuchgericht">Handelsgericht Wien</span>
              </p>
              <p>Parkring 18/2, 1010 Wien, Austria</p>
              <p>+43.660.3709277</p>
              <p>office@dmc.insure</p>
              <p>www.dmc.insure</p>
            </div>
            <p css={{ fontWeight: 500 }}>
              Versicherungsvermittler in der Form Versicherungsmakler
              <br />
              und Berater in Versicherungsangelegenheiten.
            </p>
            <p css={{ marginTop: '1.5em' }}>
              © {new Date().getFullYear()}
              {` `}
              Douglas · Machat & Cie. GmbH
            </p>
          </footer>
        </div>
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
