import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

const Header = ({ siteTitle, siteDescription }) => (
  <header>
    <div
      css={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `3rem 1.5rem`,
        textAlign: 'center',
      }}
    >
      <h1
        css={{
          margin: 0,
          lineHeight: 1,
          display: 'inline-block',
          marginBottom: '0.275em',
          fontFamily: 'Alegreya SC',
          letterSpacing: '0.05em',
          fontWeight: 400,
          fontSize: 24,
        }}
      >
        <Link
          to="/"
          css={{
            textDecoration: `none`,
            color: 'black',
          }}
        >
          {siteTitle}
        </Link>
      </h1>
      <p
        css={{
          letterSpacing: '0.1em',
          fontFamily: 'Alegreya Sans SC',
          fontSize: 24,
          textTransform: 'lowercase',
        }}
      >
        {siteDescription}
      </p>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  siteDescription: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  siteDescription: ``,
}

export default Header
